/* You can add global styles to this file, and also import other style files */
@import '~bootstrap/dist/css/bootstrap.min.css';
@import '~@angular/material/prebuilt-themes/indigo-pink.css';
@import './assets/css/variable.scss';
html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

:root {
    --bg-color: #ffffff; /* Light mode background color */
    --text-color: #000000; /* Light mode text color */
  }
  
  .dark-theme {
    --bg-color: #1B2531; /* Dark mode background color */
    --text-color: #ffffff; /* Dark mode text color */
  }
  
  .inVisible {
    visibility: hidden !important
  }
  
  .example-spacer {
    flex: 1 1 auto;
  } 


  
  .mat-mdc-flat-button.mat-primary,
  .mat-mdc-raised-button.mat-primary,
  .mat-mdc-fab.mat-primary,
  .mat-mdc-mini-fab.mat-primary {
    &:not(:disabled){
      background-color: $btn-primary-bg !important;
      color: $btn-primary-color ;
    }
    box-shadow: none;
    font-weight: normal;
    padding: $btn-padding ;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-mdc-raised-button.mat-button-disabled{
    color :#10b5df42!important;
  }
  
  .mat-mdc-flat-button.mat-secondary,
  .mat-mdc-raised-button.mat-secondary,
  .mat-mdc-fab.mat-secondary,
  .mat-mdc-mini-fab.mat-secondary {
    &:not(:disabled){
      background-color: $btn-secondary-bg !important;
      color: $btn-secondary-color !important;
    }
    box-shadow: none;
    font-weight: normal;
    padding: $btn-padding  !important;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-mdc-stroked-button.mat-primary {
    color: #242424;
    border-color: #242424;
    box-shadow: none;
    font-weight: normal;
    min-width: 100px !important;
    font-size: 15px;
  }
  
  .mat-input-element:disabled{
      color:rgba(0, 0, 0, 0.9) !important;
  }
  
  
 
 
  .toast-bootstrap-compatibility-fix {
    opacity:1;
  }

  .px-2 {
      padding-right: 0.5rem !important;
      padding-left: 0.5rem !important;
  }
  
 

  .ps {
      -ms-overflow-style: none;
      -ms-touch-action: auto;
      overflow: hidden!important;
      overflow-anchor: none;
      touch-action: auto;
  }
  
  
 
  textarea {
      resize: vertical;
      margin: 0;
      font-family: inherit;
      font-size: inherit;
      line-height: inherit;
  }
  
  web

  
  /* You can add global styles to this file, and also import other style files */
  
  /* Importing Bootstrap SCSS file. */
  // @import '~bootstrap/scss/bootstrap';
  body {
      font-family: 'Noto Sans', Helvetica, arial, sans-serif !important;
      // overflow: hidden !important;

    }
  
  
 
 
    .p-s{
      position: relative;
    } 
  
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    } 
  
    .container-section {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-flow: row wrap;
      width: 100%;
      height: calc(100vh - 80px);
  } 
  
  .btn-color{
    background-color: #003A8C !important;
    color:#fff !important;
  } 
  
 
  
    /* CSS for small screens */
    @media (max-width: 767px) {
      .title-text{
        font-size: 15px;
      }
      .upload-dialog{
       width: 80%;
      }
      .lds-dual-ring:after {
        width: 50px;
        height: 50px;
      }
      @keyframes lds-dual-ring {
        0% {
          transform: rotate(0deg);
        }
        100% {
          transform: rotate(360deg);
        }
      }
    
    }
    
    /* CSS for medium screens */
    @media (min-width: 768px) and (max-width: 1023px) {
      .title-text{
        font-size: 19px;
      }
      .upload-dialog{
        width: 50%;
        min-height: 175px;
        font-size: 17px;
        letter-spacing: 1px;
      }
    
    }
    
    
    /* CSS for large screens */
    @media (min-width: 1024px) {
      .upload-dialog{
        width: 27%;
        min-height: 175px;
        font-size: 17px;
        letter-spacing: 1px;
      }
    
    } 


 
    .disable{
      pointer-events: none;
    } 





.loader-container {
  position: fixed;
  width: 100%;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #dae8f788;
  z-index: 100;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.lds-dual-ring {
  display: block;
  position: relative;
}

.lds-dual-ring:after {
  content: ' ';
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
  border: 6px solid #003a8c;
  border-color: #003a8c transparent #40a9ff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.loading-text {
  font-size: 17px;
  margin-top: 10px;
  color: #003a8c;
  font-weight: 600;
  font-family: monospace;
  text-shadow: 0px 0px 20px #bcbcbc;
}

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
 
  .custom-toast {
    position: absolute;
    top: 90px;
    right: 10px;
  }

  //Loader Css 
  .circles {
    display: flex;
    justify-content: center;
  }
  
  .circles div {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin: 5px;
    background-color: #003a8c;
    animation: colorChange .6s linear infinite;
  }
  
  .circles div:nth-child(1) { animation-delay: 05s; }
  .circles div:nth-child(2) { animation-delay: .05s; }
  .circles div:nth-child(3) { animation-delay: .15s; }
  .circles div:nth-child(4) { animation-delay: .20s; }
  .circles div:nth-child(5) { animation-delay: .25s; }
  .circles div:nth-child(6) { animation-delay: .30s; }
  .circles div:nth-child(7) { animation-delay: .30s; }
  .circles div:nth-child(8) { animation-delay: .35s; }
  

  @keyframes colorChange {
    to { background-color: transparent; }
  }

  .info-dialog{
    width: 27%;
    min-height: 175px;
    font-size: 17px;
    letter-spacing: 1px;
    border-radius: 25px;
    overflow: hidden;
  }

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #b0b0b0;
}

::-webkit-scrollbar-thumb:hover {
  background: #878787;
}

.custom-dialog-class{
  border-radius: 20px;
  overflow: hidden;
}

/* Importing Bootstrap SCSS file. */
@import 'bootstrap/scss/bootstrap';
