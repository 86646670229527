

$white:#ffffff;
$black: #242424;
$blue: #003a8c;
$green: #009700;
$shadow:#ddd;



$btn-default-bg:             $blue;
$btn-default-color:         #ffffff;
$btn-default-border-color:  #fff;

$btn-primary-bg:            $blue;
$btn-primary-color:         #ffffff;
$btn-primary-border-color:  #fff;

$btn-secondary-bg:          #dee2e6;
$btn-secondary-color:       #242424;
$btn-secondary-border-color:#ffffff;

$btn-success-bg:            #1cff42;
$btn-success-color:         #000000;
$btn-success-border-color:  #ffffff;

$btn-warning-bg:            #ff8800;
$btn-warning-color:         #242424;
$btn-warning-border-color:  #242424;

$btn-danger-bg:             #ff0000;
$btn-danger-color:          #000000;
$btn-danger-border-color:   #ffffff;

$btn-light-bg:              #fff;
$btn-light-color:            #000;
$btn-light-border-color:     #fff;

$btn-dark-bg:               #000000;
$btn-dark-color:            #ffffff;
$btn-dark-border-color:     #fff;

$btn-info-bg:               #242424;
$btn-info-color:            #242424;
$btn-info-border-color:     #242424;

$btn-rose-bg:               #242424;
$btn-rose-color:            #242424;
$btn-rose-border-color:     #242424;


$btn-height: 46px;
$btn-width:  max-content;
$btn-border-radius:  5px;

$btn-padding: 5px 20px;

$box-shadow: 0px 1px 5px #f1f1f1; 


$input-field-size:13px;
$input-field-bg: #ffffff;
$input-field-color: #000000;
$input-field-border-radius: 0px;

$layer-back-drop:#efefef96;




// Grid Variable's


$grid-header-bg:        rgba(0, 0, 0, 0.12) !important;
$grid-header-color:     #000000b8;

$grid-data-bg:          #ffffff;
$grid-data-color:       #242424;

$grid-data-odd-bg:      #ffffff;
$grid-data-odd-color:   #200E32;

$grid-data-even-bg:     #f9f9f9;
$grid-data-even-color:  #200E32;

$grid-data-active-bg:   #f9f9f9;
$grid-data-active-color:#200E32;


$grid-header-text-size:     13.5px;
$grid-header-text-weight:   600;

$grid-data-text-size:       14px;
$grid-data-text-weight:     500;
 